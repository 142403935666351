import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"amp-module-page"},[_c('amp-row-first',{scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.blogpost.id && !_vm.isEditable)?_c('status-chip',{staticClass:"mb-3",class:{
                    'amp-module-element-hoisted':
                        _vm.$vuetify.breakpoint.mdAndUp
                },attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp,"status":_vm.status}}):_vm._e(),(_vm.isLoading)?_c(VSkeletonLoader,{staticClass:"pt-2 pb-1",attrs:{"loading":"","type":"heading"}}):_c('span',[(_vm.isRejected)?_c('a-alert',[_vm._v(" Your AMP has been declined for distribution to MSN. Please enhance the quality of your blog post or modify your distribution publishing settings to exclude MSN, and then resubmit. "+_vm._s(_vm.rejectionReason)+" ")]):_vm._e(),_c('h2',{staticClass:"font-weight-medium text-justify"},[_vm._v(" "+_vm._s(_vm.blogpost.headline || ' ')+" ")])],1),_c(VDivider,{staticClass:"my-4"})]},proxy:true}])}),_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.isLoading)?_c(VSkeletonLoader,{staticClass:"mx-n4",attrs:{"loading":"","type":"list-item-three-line"}}):_c('h3',{staticClass:"font-weight-regular text-justify",domProps:{"innerHTML":_vm._s(_vm.blogpost.summary || '&#160;')}}),_c(VDivider,{staticClass:"my-4"})]},proxy:true}])}),_c('amp-row',{scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.isLoading)?_c(VSkeletonLoader,{attrs:{"elevation":"2","type":"image","width":"10em","height":"10em"}}):_c('div',{staticClass:"py-4"},[_c('media-preview-list',{attrs:{"media-resources":_vm.blogpost.media_resources,"allow-add":false,"allow-delete":false,"allow-edit":false}})],1),_c(VDivider,{staticClass:"my-4"})]},proxy:true}])}),_c('amp-row-last',{scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.isLoading)?_c(VSkeletonLoader,{staticClass:"py-2",attrs:{"loading":"","type":"paragraph@3"}}):_c('h3',{staticClass:"font-weight-regular text-justify",domProps:{"innerHTML":_vm._s(_vm.blogpost.content || '&#160;')}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }