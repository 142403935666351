<template>
    <v-container class="amp-module-page">
        <amp-row-first>
            <template #input>
                <status-chip
                    v-if="blogpost.id && !isEditable"
                    class="mb-3"
                    :class="{
                        'amp-module-element-hoisted':
                            $vuetify.breakpoint.mdAndUp
                    }"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :status="status"
                />

                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="heading"
                    class="pt-2 pb-1"
                />
                <span v-else>
                    <a-alert v-if="isRejected">
                        Your AMP has been declined for distribution to MSN.
                        Please enhance the quality of your blog post or modify
                        your distribution publishing settings to exclude MSN,
                        and then resubmit. {{ rejectionReason }}
                    </a-alert>
                    <h2 class="font-weight-medium text-justify">
                        {{ blogpost.headline || '&#160;' }}
                    </h2>
                </span>

                <v-divider class="my-4" />
            </template>
        </amp-row-first>

        <amp-row>
            <template #input>
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="list-item-three-line"
                    class="mx-n4"
                />
                <!-- eslint-disable vue/no-v-html -->
                <h3
                    v-else
                    class="font-weight-regular text-justify"
                    v-html="blogpost.summary || '&#160;'"
                ></h3>
                <!-- eslint-enable vue/no-v-html -->
                <v-divider class="my-4" />
            </template>
        </amp-row>

        <amp-row>
            <template #input>
                <v-skeleton-loader
                    v-if="isLoading"
                    elevation="2"
                    type="image"
                    width="10em"
                    height="10em"
                />

                <div v-else class="py-4">
                    <media-preview-list
                        :media-resources="blogpost.media_resources"
                        :allow-add="false"
                        :allow-delete="false"
                        :allow-edit="false"
                    />
                </div>

                <v-divider class="my-4" />
            </template>
        </amp-row>

        <amp-row-last>
            <template #input>
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="paragraph@3"
                    class="py-2"
                />
                <!-- eslint-disable vue/no-v-html -->
                <h3
                    v-else
                    class="font-weight-regular text-justify"
                    v-html="blogpost.content || '&#160;'"
                ></h3>
                <!-- eslint-enable vue/no-v-html -->
            </template>
        </amp-row-last>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Endpoint } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast
} from '@/components/AmpModule/AmpPage';

import { AAlert } from '@/components/AAlert';

import { MediaPreviewList } from '@/components/Media';
import { StatusChip } from '@/components/StatusChip';

import type { AmpModules } from '@/types/Announcement';
import { BlogPostStatus, type BlogPost as IBlogPost } from '@/types/BlogPost';
import type { ModuleLink } from '@/types/ModuleLink';
import VideoPlaceholderSVG from '@/assets/img/video-placeholder.svg';

@Component({
    components: {
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        MediaPreviewList,
        StatusChip,
        AAlert
    },
    computed: {
        ...mapGetters('user', ['isClient'])
    }
})
export default class BlogPost extends mixins(Endpoint) {
    isClient!: boolean;

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    blogpost: Partial<IBlogPost> = {};

    isMsn = false;

    endpoint = '/blog_posts/review';

    get link(): ModuleLink[] {
        return [
            {
                type: 'primary',
                label: 'Edit',
                to: this.editLink,
                warn: this.isClient && this.isMsn
            }
        ];
    }

    get moduleId() {
        return this.modules?.blog_post_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get editLink() {
        return `/announcements/edit/${this.announcementId}/blog-post`;
    }

    get isEditable() {
        return this.blogpost.is_editable;
    }

    get status() {
        return this.blogpost.status_string;
    }

    get isLive() {
        return this.blogpost.is_live;
    }

    get isRejected() {
        return this.blogpost.status === BlogPostStatus.Rejected;
    }

    get rejectionReason() {
        if (this.blogpost.approval_message) {
            return `Reason: ${this.blogpost.approval_message}`;
        }

        return null;
    }

    onMounted() {
        if (this.moduleId) {
            this.load();
        } else {
            this.edit();
        }
    }

    onData(data: { blogPost: IBlogPost; isMsn: boolean }) {
        this.blogpost = data.blogPost;

        this.setVideoPlaceholders();

        this.isMsn = data.isMsn || false;

        this.emitLinks();
    }

    setVideoPlaceholders() {
        const content = this.blogpost.content;

        if (content === undefined) {
            return;
        }

        const hasVideoPlaceholder = content.includes('{video_pr:link}');

        if (hasVideoPlaceholder) {
            this.blogpost.content = content.replace(
                /{video_pr:link}/gi,
                `<img src="${VideoPlaceholderSVG}" alt="{video_pr:link}" title="Video will appear at this section after distribution">`
            );
        }
    }

    emitLinks() {
        this.$emit('links', this.isEditable ? this.link : []);
    }

    edit() {
        return this.$router.push(this.editLink);
    }
}
</script>
